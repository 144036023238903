<template>
  <section>
    <vue-headful :title="title" />
    <v-container>
      <v-row no-gutters>
        <v-col cols="12">
          <h1 class="fontD mt-5 pa-3 text-left">{{ subtitle }}</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" v-for="(item, index) in items" :key="index">
          <div v-html="item.parrafo"></div>
        </v-col>
      </v-row>
      <v-overlay :value="overlay">
        <v-progress-circular
          indeterminate
          size="64"
          color="#007BFF"
        ></v-progress-circular>
      </v-overlay>
    </v-container>
  </section>
</template>
<script>
import vueHeadful from "vue-headful";
export default {
  components: {
    vueHeadful,
  },
  data: () => ({
    overlay: false,
    title: "Términos y Condiciones",
    subtitle: "Nuestros términos y condiciones de servicio",
    items: [
      {
        parrafo:
          "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Perferendis modi officia odit neque commodi expedita maxime ipsa nam? Deleniti, dolorem?",
      },
    ],
  }),
  created() {
    this.getPolices();
  },
  methods: {
    getPolices: async function() {
      await this.$http
        .get(this.$keys("TERMINOS_CONDICIONES"))
        .then((response) => {
          if (response.status === 200) {
            this.overlay = true;
            this.items[0].parrafo = response.data;
          }
        })
        .catch();
      this.overlay = true;
    },
  },
  watch: {
    overlay(val) {
      val &&
        setTimeout(() => {
          this.overlay = false;
        }, 1500);
    },
  },
};
</script>
<style>
.fontD {
  color: #616161 !important;
  font-weight: 600;
  font-size: 36px !important;
}
@media (max-width: 725px) {
  .fontD {
    font-size: 22px !important;
  }
}
.text-p {
  color: #000 !important;
  font-weight: 600;
  font-size: 18px !important;
}
.v-application p {
  margin-bottom: 2px !important;
}
.v-application p {
  color: rgba(0, 0, 0, 0.6) !important;
}
.v-dialog > .v-card > .v-card__title {
  color: rgba(0, 0, 0, 0.6) !important;
}
</style>
